@import "./constants";

.precima-go-body {
  .home-sidenav.mat-drawer {
    z-index: 0;

    .page-overlay {
      padding-left: 460px;
    }
  }

  .mat-toolbar.pb-header-toolbar {
    flex-direction: row !important;
    height: 48px;
  }

  .mat-menu-panel .mat-menu-content {
    background: transparent;
  }

  .mat-menu-panel.pop-menu {
    overflow: overlay;
  }

  .pop-menu {
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0;

    .mat-menu-content {
      background: @brand-primary-blue;
    }
  }

  .ap-component-viewToggle {
    .mat-button-toggle {
      color: black;
    }

    .mat-button-toggle-checked {
      background-color: #e0e0e0;
      color: black;
    }

    .mat-button-toggle-disabled {
      background-color: #eee;
      color: white;
    }

    .mat-button-toggle-disabled {
      background-color: #bdbdbd;
    }
  }

  .ap-component-searchInput {
    .mat-input-wrapper {
      margin: 0;
      font-weight: normal;
      padding: 1px;
    }

    .mat-input-placeholder {
      color: lightgrey;
      font-weight: normal;
      font-size: 16px;
    }

    .mat-input-placeholder.mat-focused {
      color: lightgrey;
      font-weight: normal;
    }

    .mat-input-element:disabled {
      color: white;
    }

    .mat-input-placeholder.mat-float.mat-focused .mat-placeholder-required,
    input.mat-input-element:-webkit-autofill
      + .mat-input-placeholder
      .mat-placeholder-required {
      color: @status-error;
    }

    .mat-input-underline {
      border-color: white;
      margin-top: 0;
      font-weight: normal;
    }

    .mat-input-underline .mat-input-ripple {
      background-color: white;
      font-weight: normal;
    }
  }

  .ap-header-category {
    .mat-menu-panel {
      height: 200px !important;
    }
  }

  .document-container {
    &-content .mat-list-item-content {
      padding: 0 !important;
      color: #0076bc;
    }
  }

  .row.justify-content-between {
    margin: 0;
    padding-top: 2px;
    background-color: @brand-primary-blue;
  }

  .md-input-container.md-input-focused label {
    color: black;
  }

  .ap-header-sort-select,
  .ap-header-category-select {
    .mat-select-placeholder {
      color: lightgrey !important;
    }

    .mat-select-underline {
      background-color: white !important;
    }

    .mat-select-trigger {
      color: lightgrey;
    }

    .mat-select.ng-invalid.ng-touched:not(.mat-select-disabled)
      .mat-select-disabled
      .mat-select-value {
      color: white !important;
    }

    .mat-select-value {
      color: white !important;
    }

    .mat-select-arrow {
      color: white !important;
    }

    .mat-option.mat-selected {
      color: white !important;
    }
  }

  .ap-header-category-select {
    .mat-select-trigger {
      color: lightgrey;
      display: flex;
      justify-content: center;
      background: @brand-primary-blue;
      padding: 0 10px;
      height: 35px;

      .mat-select-underline,
      .mat-select-placeholder {
        display: none;
      }

      .mat-select-arrow {
        margin-left: 10px;
        margin-right: 13px;
      }

      .mat-select-value {
        flex-grow: 0;
        position: relative;
      }
    }
  }

  .ap-reports-list.mat-sidenav-container {
    color: transparent;
  }

  .category-menu .mat-menu-content {
    border-top: 4px solid @brand-primary-blue;
    background-color: #fff;
    font-size: 12px;
    font-family: 'Aktiv Grotesk App', sans-serif;
    font-weight: 400;
    box-shadow: 0 2px 4px #eee;
    width: auto;
    padding: 0;

    .item {
      cursor: pointer;
      padding: 12px 16px;

      &:hover {
        background-color: #eee;
        color: @brand-primary-blue;
      }
    }
  }

  .spinner-wrapper {
    &.bothOpened {
      .page-overlay {
        padding-left: 460px;
      }
    }

    &.navOpened {
      .page-overlay {
        padding-left: 230px;
      }
    }

    &.moduleSidenavOpened {
      .page-overlay {
        padding-left: 293px;
      }
    }

    &.neitherOpened {
      .page-overlay {
        padding-left: 63px;
      }
    }

    &.dashboard .page-overlay .loader div {
      background: rgba(255, 255, 255, 0.6) !important;
    }
  }

  .ap-header {
    .ap-header-search-box {
      display: flex;
      flex-direction: row;
      margin-right: 25px;
      width: 397px;

      .category-btn {
        background-color: @brand-primary-blue;
        color: #fff;
        font-size: 14px;
        align-items: center !important;
        font-family: Aktiv-Grotesk-App, "Aktiv Grotesk App", "Aktiv Grotesk", sans-serif;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;

        .text {
          margin: 0 10px 0 13px;
        }

        .arrow {
          margin-right: 13px;
        }
      }

      .mat-select.ap-header-category-select {
        padding-top: 0 !important;
        max-width: 200px;
        color: #fff;
        background-color: #fff;
        width: auto;
        margin-right: 0;

        .mat-select-trigger {
          width: auto;
          min-width: auto;
          max-width: 200px;
          padding: 0;
          float: left;

          .mat-select-value {
            max-width: calc(100% - 18px) !important;
            width: auto;
            padding: 0;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span.mat-select-value-text {
              width: auto;
              max-width: 200px;
              font-size: 14px;
              -ms-flex-align: center !important;
              align-items: center !important;
              font-family: Aktiv-Grotesk-App, "Aktiv Grotesk App", "Aktiv Grotesk", sans-serif;
              font-style: normal;
              font-weight: 400;

              span {
                width: auto;
                margin-left: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .mat-select-arrow {
            width: 5px;
            height: 5px;
            display: inline-block !important;
            padding-bottom: 5px;

            &-wrapper {
              -ms-flex-align: center !important;
              align-items: center !important;
              width: min-content;
            }
          }
        }

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          color: red;
          width: auto;

          .mat-select-trigger {
            width: auto;

            .mat-select-value {
              width: auto;

              span.mat-select-value-text {
                width: auto;

                span {
                  width: auto;
                }
              }
            }
          }

          input {
            color: #eee;
          }
        }
      }

      input.search-box {
        height: 35px;
        min-width: 161px;
        padding: 10px;
        border: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #fff;

        &::placeholder {
          color: @Grey30;
        }
      }

      i.linear-icon.linear-icon--magnifier {
        cursor: pointer;
        height: 35px;
        width: 35px !important;
        min-width: 35px;
        color: white;
        background: @Grey70;
        display: flex;
        justify-content: center;
        align-items: center !important;
      }
    }

    .pb-header-toolbar-title {
      margin-right: 0.5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 50%;
      line-height: normal !important;
      font-family: Aktiv-Grotesk-App-Thin, "Aktiv Grotesk App Thin", "Aktiv Grotesk", sans-serif !important;
      font-size: 32px !important;
      font-style: normal !important;
      font-weight: 200 !important;
    }

    &-sort-select {
      padding-bottom: 0;
      color: @Grey50 !important;
    }

    .mat-select {
      min-width: min-content;
    }
  }

  .ap-reports {
    .page-overlay {
      position: relative !important;
      background-color: transparent !important;
    }

    .mat-select-arrow-wrapper {
      width: 10px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 5px;
    }
  }

  .company-logo {
    background: url(./images/precima-logos/precima-suite-logo.svg) no-repeat 50%;
    background-size: 300px 76px;
    height: 76px;
    width: 300px;
  }

  .brandloyalty-precima-logo {
    background: url(./images/brandloyalty/brandloyalty-precima-logo.png)
      no-repeat 50%;
    background-size: 120px 35px;
    height: 35px;
    width: 120px;
  }

  .loblaws-precima-logo {
    background: url(./images/loblaws/loblaws-precima-logo.png) no-repeat 50%;
    background-size: 120px 35px;
    height: 35px;
    width: 120px;
  }

  .jumbo-precima-logo {
    background: url(./images/jumbo/jumbo-precima-logo.png) no-repeat 50%;
    background-size: 120px 35px;
    height: 35px;
    width: 120px;
  }

  .brandloyalty-logo {
    background: url(./images/brandloyalty/brandloyalty-logo-grey-white.png)
      no-repeat 50%;
    background-size: 190px 40px;
    height: 40px;
    width: 190px;
  }

  .jumbo-logo {
    background: url(./images/jumbo/jumbo-logo-transparent.png) no-repeat 50%;
    background-size: 150px 30px;
    height: 30px;
    width: 150px;
  }

  .loblaws-logo {
    // background: url(./images/loblaws/loblaws-stacked-en-white-oneline.png) no-repeat 50%;
    // background-size: 400px 30px;
    // height: 30px;
    // width: 400px;
    background: url(./images/loblaws/topshelf-LCL-large.png) no-repeat 50%;
    background-size: 170px 70px;
    height: 70px;
    width: 170px;
  }

  .shoppersdrugmart-logo {
    background: url(./images/sdm/topshelf-SDM-large.png) no-repeat 50%;
    background-size: 170px 70px;
    height: 70px;
    width: 170px;
  }

  .ap-company-logo-title {
    background: url(./images/precima-logos/precima-green-white.png) no-repeat
      50%;
    background-size: 120px 35px;
    height: 35px;
    width: 120px;
  }

  .ap-brandloyaltylogo {
    background: url(./images/brandloyalty/brandloyalty-logo-yellow-white.png)
      no-repeat 50%;
    background-size: 12vw 5vw;
    height: 5vw;
    width: 12vw;
  }

  .dev-login-logo, .demo-login-logo {
    background: url(./images/demo/demo-black.png) no-repeat 50%;
    background-size: contain;
    height: 100%;
    width: 600px;
  }

  //Login logos
  .jumbo-login-logo {
    background: url(./images/jumbo/jumbo-login-logo.png) no-repeat 50%;
    background-size: contain;
    height: 100%;
    width: 600px;
  }

  .brandloyalty-login-logo {
    background: url(./images/brandloyalty/brandloyalty-logo-yellow-white.png)
      no-repeat 50%;
    background-size: contain;
    height: 100%;
    width: 500px;
  }

  .loblaws-login-logo {
    //background: url(./images/loblaws/LCL-Stacked-en-white.png) no-repeat 50%;
    background: url(./images/loblaws/topshelf-LCL-large.png) no-repeat 50%;
    background-size: contain;
    height: 100%; //width: 430px;
    width: 500px;
  }

  .shoppersdrugmart-login-logo {
    background: url(./images/sdm/topshelf-SDM-large.png) no-repeat 50%;
    background-size: contain;
    height: 100%;
    width: 500px;
  }

  .hbc-login-logo {
    background: url(./images/hbc/hbc-logo-login.svg) no-repeat 50%;
    background-size: contain;
    height: 100%; //width: 430px;
    width: 500px;
  }

  .usfoods-login-logo {
    background: url(./images/usfoods/usfoods-logo-login.png) no-repeat 50%;
    background-size: contain;
    height: 100%; //width: 430px;
    width: 500px;
  }

  .staples-login-logo {
    background: url(./images/staples/Staples_Logo_Black.png) no-repeat 50%;
    background-size: contain;
    height: 100%; //width: 430px;
    width: 500px;
  }

  /*
  .shoppersdrugmart-login-logo {
    background: url(./images/sdm/sdm-logo.png) no-repeat 50%;
    background-size: contain;
    height: 100%;
    width: 630px;
  }
  */
  .circle-checkMark {
    background: url(./images/icons/check.png) no-repeat 50%;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
  }

  .precima-logo-transparent {
    background: url(./images/precima-logos/precima-logo-white.png) no-repeat 50%;
    background-size: 145px 40px;
    height: 40px;
    width: 145px;
  }

  .precima-logo-transparent-footer {
    background: url(./images/precima-logos/precima-logo-white.png) no-repeat 50%;
    background-size: 88px 26px;
    height: 26px;
    width: 88px;
  }

  .demo-navbar-logo-small {
    background: url(./images/demo/demo-small-side-bar.svg);
  }

  .riteaid-navbar-logo-small {
    background: url(./images/riteaid/RA_logo_small.svg);
  }

  .jumbo-navbar-logo-small {
    background: url(./images/jumbo/jumbo-small-side-bar.svg);
  }

  .loblaws-navbar-logo-small {
    background: url(./images/loblaws/loblaws-small-side-bar.svg);
  }

  .shoppersdrugmart-navbar-logo-small {
    background: url(./images/sdm/shoppersdrugmart-small-side-bar.svg);
  }

  .brandloyalty-navbar-logo-small {
    background: url(./images/brandloyalty/brandloyalty-small-side-bar.svg);
  }

  .hbc-navbar-logo-small {
    background: url(./images/hbc/hbc-small-side-bar.png);
  }

  .usfoods-navbar-logo-small {
    background: url(./images/usfoods/usfoods-navbar-small.svg);
  }

  .cvs-navbar-logo-small {
    background: url(./images/cvs/extracare_ding.svg);
  }

  .staples-navbar-logo-small {
    background: url(./images/staples/Ico_Staples.svg);
  }

  .preferences-container {
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
      background-color: black !important;
      /*replace with your color*/
    }

    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
      background-color: @Grey15 !important;
      /*replace with your color*/
    }
  }

  .header-menu-wrapper {
    .mat-menu-content {
      width: auto !important;
      background: #fff !important;

      .mat-menu-item {
        background-color: #fff;
      }

      .mat-menu-item-span {
        margin-left: 0 !important;
        margin-right: 0 !important;
        color: #191919 !important;
      }

      .mat-menu-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        line-height: 38px;
        height: 38px;
        font-family: "Aktiv-Grotesk-App", "Aktiv Grotesk App", "Aktiv Grotesk", sans-serif;
        font-size: 12pt;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04) !important;
        }
      }
    }
  }

  .ap-header-sort-select {
    width: 100%;

    .mat-select-trigger {
      width: 100%;
      min-width: 61px !important;
      border-bottom: solid black 1px;
      padding: 0;

      .mat-select-value-text {
        color: @Grey60;
        font-family: Aktiv-Grotesk-App, "Aktiv Grotesk App", "Aktiv Grotesk", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .mat-select-arrow {
        color: @brand-primary-blue !important;
        height: 20px;
      }
    }
  }

  .list-table {
    td {
      text-align: left;
    }
  }

  .row.gridview-row {
    margin-left: 0 !important;
  }

  .reports-header-sorting {
    .mat-select-arrow {
      margin-top: 1em;
    }
  }

  .mat-select-content {
    .mat-option {
      font-size: 12px !important;
    }
  }

  .home-container .mat-sidenav-content {
    overflow-y: hidden;
  }
}

body {
  height: 100%;
  width: 100%;
  background-color: #fff !important;
  overflow: hidden; // enable collapse mainnav after app selection all the time

  &.insights-portal {
    height: auto !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #B3B3B3;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  .support-menu {
    position: absolute;
    top: 0;

    .item {
      .app-name {
        text-transform: capitalize;
      }
    }
  }

  .pop-menu {
    left: 16px;
  }

  .cdk-overlay-pane .mat-menu-panel {
    background-color: #fff;

    .mat-menu-content {
      background: #fff;
    }
  }

  .cdk-overlay-pane .mat-menu-panel.pop-menu {
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background-color: black !important;

    .mat-menu-content {
      background: black !important;
      padding: 0;
    }
  }

  .btm-menu.mat-menu-panel,
  .support-menu {
    border-radius: 0;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);

    .mat-menu-content {
      background-color: #fff;
      font-size: 12px;
      font-family: 'Aktiv Grotesk App', sans-serif;
      font-weight: 600;
      box-shadow: 0 2px 4px #eee;
      width: auto;
      padding: 0;

      .item {
        cursor: pointer;
        padding: 16px 74px 16px 12px;

        &:hover {
          background-color: @color-background-row-hover;
        }
      }

      .item.logout {
        border-top: 2px solid @Grey15;
      }
    }
  }

  .btm-menu.lang-menu.mat-menu-panel {
    min-width: auto;

    .mat-menu-content {
      .item {
        &:hover {
          background-color: #eee;
        }
      }
    }
  }

  .btm-menu.lang-menu.mat-menu-panel.nav-closed {
    margin-top: -36px;
  }

  .btm-menu.user-menu.mat-menu-panel.nav-closed {
    margin-top: -36px;
  }

  .demo-navbar-logo {
    background: url(./images/demo/demo-white.png) no-repeat 50%;
    background-size: contain;
    background-position: center;
    height: 60px;
    width: 80px;
  }

  .riteaid-navbar-logo {
    background: url(./images/riteaid/RA_logo_White.png) no-repeat 50%;
    background-size: contain;
    background-position: center;
    height: 60px;
    width: 110px;
  }

  .loblaws-navbar-logo {
    background: url(./images/loblaws/topshelf-LCL-large.png) no-repeat 50%;
    background-size: 150px 60px;
    height: 60px;
    width: 150px;
  }

  .brandloyalty-navbar-logo {
    background: url(./images/brandloyalty/brandloyalty-white.png) no-repeat 50%;
    background-size: 190px 40px;
    height: 60px;
    width: 190px;
  }

  .jumbo-navbar-logo {
    background: url(./images/jumbo/jumbo-white.png) no-repeat 50%;
    background-size: 150px 30px;
    height: 60px;
    width: 150px;
  }

  .shoppersdrugmart-navbar-logo {
    background: url(./images/sdm/topshelf-SDM-large.png) no-repeat 50%;
    background-size: 150px 60px;
    height: 60px;
    width: 150px;
  }

  .hbc-navbar-logo {
    background: url(./images/hbc/hbc-white.png) no-repeat 50%;
    background-size: 90px 60px;
    height: 60px;
    width: 90px;
  }

  .usfoods-navbar-logo {
    background: url(./images/usfoods/usfoods-navbar-full.png) no-repeat 50%;
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }

  .cvs-navbar-logo {
    background: url(./images/cvs/extracare_wordmark_White.svg) no-repeat 50%;
    background-size: 160px 20px;
    height: 20px;
    width: 160px;
  }
}

html {
  height: 100%;
  width: 100%;
}

.ap-company-logo-title {
  background: url(./images/precima-logos/precima-green-white.png) no-repeat 50%;
  background-size: 120px 35px;
  height: 35px;
  width: 120px;
}

.ap-brandloyaltylogo {
  background: url(./images/brandloyalty/brandloyalty-logo-yellow-white.png)
    no-repeat 50%;
  background-size: 12vw 5vw;
  height: 5vw;
  width: 12vw;
}

//Login logos
.jumbo-login-logo {
  background: url(./images/jumbo/jumbo-login-logo.png) no-repeat 50%;
  background-size: contain;
  height: 100%;
  width: 600px;
}

.brandloyalty-login-logo {
  background: url(./images/brandloyalty/brandloyalty-logo-yellow-white.png)
    no-repeat 50%;
  background-size: contain;
  height: 100%;
  width: 500px;
}

.loblaws-login-logo {
  //background: url(./images/loblaws/LCL-Stacked-en-white.png) no-repeat 50%;
  background: url(./images/loblaws/topshelf-LCL-large.png) no-repeat 50%;
  background-size: contain;
  height: 100%; //width: 430px;
  width: 500px;
}

.shoppersdrugmart-login-logo {
  background: url(./images/sdm/topshelf-SDM-large.png) no-repeat 50%;
  background-size: contain;
  height: 100%;
  width: 500px;
}

.hbc-login-logo {
  background: url(./images/hbc/hbc-logo-login.svg) no-repeat 50%;
  background-size: contain;
  height: 100%; //width: 430px;
  width: 500px;
}

.usfoods-login-logo {
  background: url(./images/usfoods/usfoods-logo-login.png) no-repeat 50%;
  background-size: contain;
  height: 100%; //width: 430px;
  width: 500px;
}

.cvs-login-logo {
  //background: url(./images/loblaws/LCL-Stacked-en-white.png) no-repeat 50%;
  background: url(./images/cvs/extracare_wordmark_Color.svg) no-repeat 50%;
  background-size: contain;
  height: 100%; //width: 430px;
  width: 900px;
}

/*
.shoppersdrugmart-login-logo {
  background: url(./images/sdm/sdm-logo.png) no-repeat 50%;
  background-size: contain;
  height: 100%;
  width: 630px;
}
*/

.riteaid-login-logo {
  background: url(./images/riteaid/RA_logo_Black.png) no-repeat 50%;
  background-size: contain;
  height: 100%;
  width: 900px;
}

.circle-checkMark {
  background: url(./images/icons/check.png) no-repeat 50%;
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
}

.precima-logo-transparent {
  background: url(./images/precima-logos/precima-logo-white.png) no-repeat 50%;
  background-size: 145px 40px;
  height: 40px;
  width: 145px;
}

.precima-logo-transparent-footer {
  background: url(./images/precima-logos/precima-logo-white.png) no-repeat 50%;
  background-size: 88px 26px;
  height: 26px;
  width: 88px;
}

.jumbo-navbar-logo {
  background: url(./images/jumbo/jumbo-white.png) no-repeat 50%;
  background-size: 150px 30px;
  height: 30px;
  width: 150px;
}

.loblaws-navbar-logo {
  background: url(./images/loblaws/topshelf-LCL-large.png) no-repeat 50%;
  background-size: contain;
  height: 100%;
  width: 150px;
}

.shoppersdrugmart-navbar-logo {
  background: url(./images/sdm/topshelf-SDM-large.png) no-repeat 50%;
  background-size: contain;
  height: 100%;
  width: 150px;
}

.hbc-navbar-logo {
  background: url(./images/hbc/hbc-white.png) no-repeat 50%;
  background-size: 90px 60px;
  height: 60px;
  width: 90px;
}

.usfoods-navbar-logo {
  background: url(./images/usfoods/usfoods-navbar-full.png) no-repeat 50%;
  background-size: 70px 70px;
  height: 70px;
  width: 70px;
}

.cvs-navbar-logo {
  background: url(./images/cvs/extracare_wordmark_White.svg) no-repeat 50%;
  background-size: 200px 200px;
  height: 200px;
  width: 200px;
}

.staples-navbar-logo {
  background: url(./images/staples/Staples_Logo_White.svg) no-repeat 50%;
  background-size: 150px 23px;
  height: 23px;
  width: 150px;
}

.riteaid-navbar-logo {
  background: url(./images/riteaid/RA_logo_White.png) no-repeat 50%;
  background-size: contain;
  background-position: center;
  height: 60px;
  width: 110px;
}

.demo-navbar-logo {
  background: url(./images/demo/demo-white.png) no-repeat 50%;
  background-size: contain;
  background-position: center;
  height: 80px;
  width: 80px;
}

.demo-navbar-logo-small {
  background: url(./images/demo/demo-small-side-bar.svg);
}

.riteaid-navbar-logo-small {
  background: url(./images/riteaid/RA_logo_small.svg);
}

.jumbo-navbar-logo-small {
  background: url(./images/jumbo/jumbo-small-side-bar.svg);
}

.loblaws-navbar-logo-small {
  background: url(./images/loblaws/loblaws-small-side-bar.svg);
}

.shoppersdrugmart-navbar-logo-small {
  background: url(./images/sdm/shoppersdrugmart-small-side-bar.svg);
}

.brandloyalty-navbar-logo-small {
  background: url(./images/brandloyalty/brandloyalty-small-side-bar.svg);
}

.hbc-navbar-logo-small {
  background: url(./images/hbc/hbc-small-side-bar.png);
}

.usfoods-navbar-logo-small {
  background: url(./images/usfoods/usfoods-navbar-small.svg);
}

.cvs-navbar-logo-small {
  background: url(./images/cvs/extracare_ding.svg);
}

.preferences-container {
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #54910d !important;
    /*replace with your color*/
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #62a70f !important;
    /*replace with your color*/
  }
}

.header-menu-wrapper {
  .mat-menu-content {
    width: auto !important;
    background: #fff !important;

    .mat-menu-item {
      background-color: #fff;
    }

    .mat-menu-item-span {
      margin-left: 0 !important;
      margin-right: 0 !important;
      color: #191919 !important;
    }

    .mat-menu-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 10px;
      line-height: 38px;
      height: 38px;
      font-family: "Aktiv-Grotesk-App", "Aktiv Grotesk App", "Aktiv Grotesk", sans-serif;
      font-size: 12pt;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }
}

.ap-header-sort-select {
  width: 100%;

  .mat-select-trigger {
    width: 100%;
    min-width: 61px !important;
    border-bottom: solid black 1px;
    padding: 0;

    .mat-select-value-text {
      text-transform: uppercase;
      max-width: 200px;
    }

    .mat-select-arrow {
      color: @brand-primary-blue !important;
      height: 10px;
    }
  }
}

// for favorite icon favo/unfavo effects
.visible {
  opacity: 1;
}

.half-visible {
  //color: #ddd;
  opacity: 0.4;
}

.invisible {
  opacity: 0;
}

body.insights-portal,
body.data-portal {
  .support-menu {
    top: -20px;
    left: 30px;
  }
}

.ap-component-loginInput {
  .mat-input-element {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mat-form-field-underline {
    display: none !important;

    .mat-form-field-ripple {
      background-color: transparent !important;
    }
  }
}

.pb-tooltip {
  text-transform: capitalize;
  &.light {
    max-width: 550px !important;
  }
}

.niq-spinner {
  overflow: hidden;
}

//Necessary for Material Icons to work properly
.material-icons {
  font-family: @icon-font;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
