@icon-font: 'Material Icons';

@font-face {
  font-family: 'nielsen-icons';
  src: url('https://ng2prod.azureedge.net/ui_assets/fonts/nielsen-icons.ttf') format("truetype");
}

@aktiv-grotesk-app-thin: 'Aktiv-Grotesk-App-Thin', 'Aktiv Grotesk App Thin', 'Aktiv-Grotesk';
@aktiv-grotesk-app-light: 'Aktiv-Grotesk-App-Light', 'Aktiv Grotesk App Light', 'Aktiv-Grotesk';
@aktiv-grotesk-app-regular: 'Aktiv-Grotesk-App-Regular', 'Aktiv Grotesk App Regular', 'Aktiv-Grotesk';
@aktiv-grotesk-app-semibold: 'Aktiv-Grotesk-App-Semibold', 'Aktiv Grotesk App Semibold', 'Aktiv-Grotesk';
@aktiv-grotesk-app-bold: 'Aktiv-Grotesk-App-Bold', 'Aktiv Grotesk App Bold', 'Aktiv-Grotesk';
